<template>
  <footer class="bg-white">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div class="flex justify-center space-x-6 md:order-2">
        <div class="flex items-center bg-black text-white font-mono rounded px-2 text-sm">
          <span>{{time}}</span>
        </div>
      </div>
      <div class="mt-8 md:mt-0 md:order-1">
        <p class="text-center text-base text-gray-400 md:flex">
          {{moment().format('YYYY')}} McCoy - 
          <router-link to="/changelog" class="text-gray-800 mx-2">changelog</router-link> - 
          <a href="mailto:rutger.heijmerikx@mccoy-partners.com" class="flex ml-2">
            <Chat32 class="h-5 w-5 mr-1 text-orange-600" /> bug gevonden?
          </a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { ref } from 'vue'
import moment from 'moment'
import { Chat32 } from '@carbon/icons-vue';

export default {
  created: function () {
    this.moment = moment;
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  components: {
    Chat32
  },
  setup() {
    const interval = ref(null);
    const time = ref(null);

    interval.value = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      time.value = Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format()
    }, 1000)

    return {
      interval,
      time
    }
  },
}
</script>