<template>
  <div class="bg-gradient-to-t from-gray-100 h-full">
    <Notification />
    <HeaderNav />
    <slot />
    <Footer />
  </div>
</template>

<script>
import HeaderNav from './HeaderNav'
import Footer from './Footer'

import { ref } from 'vue'
const userNavigation = [
  { name: 'Your Profile', href: '/profile' },
]
export default {
  name: "AppLayoutPortal",
  components: {
    HeaderNav,
    Footer
  },
  computed: {
    currentUser() {
      return this.$store.state.user;
    }
  },
  setup() {
    var myDate = new Date();
    var hrs = myDate.getHours();
    var mins = myDate.getMinutes();

    const sidebarOpen = ref(false)
    let greeter = '';
    if (hrs >= 5 && ((hrs == 5 && mins >= 30) || (hrs > 5 && hrs < 12)))
      greeter = 'Good Morning';
    else if (hrs >= 12 && hrs < 18)
      greeter = 'Good Afternoon';
    else if ((hrs >= 18 && hrs < 24) || hrs > 0)
      greeter = 'Good Evening';
    else
      greeter = 'Error';

    return {
      sidebarOpen,
      userNavigation,
      greeter
    }
  },
}
</script>